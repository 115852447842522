<script>
import StandForm from "@/views/menu/stand/standForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    standService: "standService",
    fileUploadService: "fileUploadService",
    visitorLevelService: "visitorLevelService",
    toastService: "toastService",
  },
})
export default class StandEdit extends mixins(StandForm) {
  formId = "edit-stand-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      this.model.localizations.forEach((loc) => {
        if (!loc.videoPlatformType) {
          loc.videoPlatformType = "Default";
        }
      });
      await this.standService.update(this.model.id, this.model);
      this.$router.push("/stand/list");
      this.toastService.success(this.translations.success.stand_update);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.stands_edit;
  }

  async afterCreate() {
    this.model = await this.standService.read(this.$route.params.id);

    this.setVisitorLevels(
      await this.visitorLevelService.enabledLevels(this.model.id)
    );
    this.model.visitorLevels = this.currentVisitorLevelsValue();

    this.isReady = true;
  }
}
</script>
